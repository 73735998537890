/* eslint-disable no-underscore-dangle */
import React, { useState, ReactElement, useEffect, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Stack } from '@mui/system';
import {
  Collapse,
  Dialog,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import { endOfDay, format, isSameDay, isValid, parse, startOfDay } from 'date-fns';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ActionStaffModal } from 'shared/modals/ActionStaffModal/ActionStaffModal';
import { dialogStyleAddModal } from 'ui/modals/modalAdd/modalAdd';
import { inputFormStyle } from 'ui/inputs/inputsForm';
import {
  modalHeaderStyle,
  modalTitleStyle,
  modalSubtitleStyle,
  modalFormStyle,
  modalFormScrollStyle,
  modalFormWrapStyle,
  modalFormWrapInputsStyle,
  modalFormInputStyle,
} from 'ui/modals/modalGlobal';
import { themeColor, grayLightDarkOpacity } from 'utils/uiConstants';
import { modalAddFromFileContent, optionsGender } from 'utils/constants';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { AutocompleteInput } from 'shared/inputs/AutocompleteInput/AutocompleteInput';
import { useGetTaskActionStaff } from 'hooks/other/useGetTaskActionStaff';
import { useSearchPrograms } from 'hooks/programs/useSearchProgram';
import { useAddPatientFromFile } from 'hooks/patients/useAddPatientFromFile';
import { useAddPatient } from 'hooks/patients/useAddPatient';
import { useSnackbar } from 'notistack';
import { IAddPatientModalProps } from 'interfaces/patients/patientInterfaces';
import { IDataHooksProgram, IProgram, SearchParams } from 'interfaces/program/programInterfaces';
import { DatePickerDay, datePickerPopperStyled } from 'ui/datePicker/datePicker';
import { requiredPhoneByCompanies, requiredPassByCompanies } from 'utils/requiredCheck';
import { AddPatientHookResponseError, TaskId } from 'interfaces/hooks/patientsHooksInterfaces';
import { moneyRowCurrentView } from 'utils/tablesMethods';
import { useGetPeriodStaffAction } from 'hooks/periods/useGetPeriodStaffAction';
import { TooltipActionsInfo } from 'ui/tooltips/tooltipAction';
import { useSearchRelativeSlots } from 'hooks/patients/useSearchRelativeSlots';
import { useDebounce } from 'utils/useDebounce';
import { isAccessAllowed } from 'utils/isAccessAllowed';
import { Company, ErrorData } from 'interfaces/global/globalInterfaces';
import { disabledDates } from 'utils/dateHelpers';
import { analyticsTracker } from 'utils/analytics';
import { validationPatient } from './validationAddPatient';
import { ActionWithPatientFromFileModal } from '../ActionWithPatientFromFileModal/ActionWithPatientFromFileModal';
import { GetTooltipTitleRelativeType } from './GetTooltipTitleRelativeType';
import { CrossingByCompany } from '../CrossingByCompany/CrossingByCompany';
import { CrossingByCompanyPeriod } from '../CrossingByCompanyPeriod/CrossingByCompanyPeriod';

export const AddPatientModal: React.FC<IAddPatientModalProps> = ({
  open,
  companiesHR,
  selectedCompanies,
  periodInMainFilter,
  isAddingForbiddenByDates,
  handleClose,
  handleTaskIdAttach,
  celeryType,
  accessList,
  defaultPermissions,
}) => {
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalAttachFromFileOpen, setModalAttachFromFileOpen] = useState<boolean>(false);
  const [isCrossingByCompanyModalOpen, setCrossingByCompanyModalOpen] = useState<boolean>(false);
  const [isCrossingByCompanyPeriodModalOpen, setCrossingByCompanyPeriodModalOpen] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [programs, setPrograms] = useState<SearchParams[]>([]);
  // id задачи по откреплению
  const [createSlotTaskId, setCreateSlotTaskId] = useState<string | null>(null);
  // стейты для дата пикеров
  const [dateBirth, setDateBirth] = useState<Date | null>(null);
  const [datePassport, setDatePassport] = useState<Date | null>(null);
  const [dateStartProgram, setDateStartProgram] = useState<Date | null>(null);
  const [selectedPatientType, setselectedPatientType] = useState<string>('');
  const [searchRelativeText, setSearchRelativeText] = useState<string>('');
  const [programsNextPageUrl, setProgramsNextPageUrl] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const reqPhone: boolean = requiredPhoneByCompanies(companiesHR);
  const reqPass: boolean = requiredPassByCompanies(companiesHR);
  const validPatient = validationPatient(reqPhone, reqPass);
  type PatientParams = z.infer<typeof validPatient>;
  const rendersPickerDateBirth = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = dateBirth !== null ? isSameDay(date, dateBirth) : false;
    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };
  const rendersPickerDatePassport = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = datePassport !== null ? isSameDay(date, datePassport) : false;
    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };
  const rendersPickerDateStartProgram = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = dateStartProgram !== null ? isSameDay(date, dateStartProgram) : false;
    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  const isSlotsAviable = companiesHR
    ?.map((item: Company) => isAccessAllowed(item?.uuid, 'HR_slots', accessList, defaultPermissions))
    ?.some((key) => !!key);

  const { searchPatientsRelative } = useSearchRelativeSlots({
    fullname: searchRelativeText,
    holding: companiesHR[0]?.holding,
    isSlotsAviable,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const handleSearchTextRelativePatients = useCallback(
    (value: string): void => {
      setSearchRelativeText(value);
    },
    [setSearchRelativeText]
  );

  const handleSearchText = useDebounce(handleSearchTextRelativePatients, 1000);

  const { periodStaffAction } = useGetPeriodStaffAction({
    period: periodInMainFilter,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const disabledDays = (date: Date): boolean => {
    const actualDayTimeStamp = startOfDay(date).getTime();

    const canAttachSinceTimestamp = periodStaffAction
      ? startOfDay(parse(periodStaffAction.canAttachSince, 'yyyy-MM-dd', new Date())).getTime()
      : null;
    const canAttachSinceTimestampMax = periodStaffAction
      ? endOfDay(parse(periodStaffAction.canAttachOrDetachMaxDate, 'yyyy-MM-dd', new Date())).getTime()
      : null;
    if (disabledDates.some((item) => item === date.getTime())) {
      return disabledDates.some((item) => item === date.getTime());
    }
    if (canAttachSinceTimestamp && canAttachSinceTimestampMax) {
      return actualDayTimeStamp < canAttachSinceTimestamp || actualDayTimeStamp > canAttachSinceTimestampMax;
    }
    if (canAttachSinceTimestamp) {
      return actualDayTimeStamp < canAttachSinceTimestamp;
    }
    if (canAttachSinceTimestampMax) {
      return actualDayTimeStamp > canAttachSinceTimestampMax;
    }
    return false;
  };

  const {
    setValue,
    register,
    reset,
    watch,
    formState: { errors, isDirty, dirtyFields },
    setError,
    handleSubmit,
  } = useForm<PatientParams>({
    mode: 'onTouched',
    resolver: zodResolver(validationPatient(reqPhone, reqPass)),
    defaultValues: {
      last_name: '',
      first_name: '',
      middle_name: '',
      gender: '',
      birth_date: '',
      nationality: '',
      address: '',
      phone: null,
      email: '',
      position: '',
      number: '',
      passport_number: null,
      passport_date: null,
      passport_given: null,
      program: '',
      start_date: '',
    },
  });

  const { isLoadingSearchPrograms, fetchNextPage } = useSearchPrograms({
    isProgramsAllowed: isSlotsAviable,
    company: selectedCompanies[0],
    period: periodInMainFilter,
    selectedProgram: null,
    selectedServices: [],
    selectedСity: null,
    onSuccess: (data: IDataHooksProgram) => {
      const loadProgrammArr: SearchParams[] | [] = data.programs
        ? data.programs.map((item: IProgram) => ({
            id: item.programUuid,
            title: `${item.programTitle}\n(страховая премия - ${moneyRowCurrentView(item.budget)})`,
            uuid: item.programUuid,
            allowedSlotPatientTypes: item.allowedSlotPatientTypes,
          }))
        : programs;

      const filterLoadProgrammArr = loadProgrammArr
        .filter((a, i) => loadProgrammArr.findIndex((s) => a.title === s.title && a.title !== '') === i)
        .sort((a, b) => {
          if (a.title > b.title) {
            return 1;
          }
          if (a.title < b.title) {
            return -1;
          }
          return 0;
        });
      setPrograms(filterLoadProgrammArr);
      setProgramsNextPageUrl(data?.pageParams?.next_url);
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  useEffect(() => {
    if (programsNextPageUrl) {
      fetchNextPage();
    }
  }, [fetchNextPage, programsNextPageUrl, programs]);

  const modalClose = (): void => {
    handleSearchText('');
    setselectedPatientType('');
    setDateBirth(null);
    setDatePassport(null);
    setDateStartProgram(null);
    reset();
    handleTaskIdAttach('');
    setCrossingByCompanyPeriodModalOpen(false);
    setCrossingByCompanyModalOpen(false);
    handleClose();
  };

  const { isLoadingAddPatient, handleAddPatient } = useAddPatient({
    dirtyFields,
    onSuccess: (res) => {
      if (res.ok) {
        enqueueSnackbar('Пациент будет прикреплен в ближайшее время', { variant: 'success' });
        analyticsTracker.trackAttachmentFormSuccess();
        queryClient.invalidateQueries('dataCompanyPatients');
        modalClose();
      } else {
        res.json().then((data: AddPatientHookResponseError) => {
          data.errors.forEach((item) => {
            const { field } = item;
            setError(field, {
              type: 'custom',
              message: item.message,
            });
            if (item.message === 'Диалог: Пациент прикреплен в другой компании в этот же период. Прикрепить?') {
              setCrossingByCompanyPeriodModalOpen(true);
            } else if (
              item.message === 'Диалог: Пациент пересекается в рамках компании-срока обслуживания. Прикрепить?'
            ) {
              setCrossingByCompanyModalOpen(true);
            } else {
              enqueueSnackbar(item.message, { variant: 'error' });
            }
          });
        });
      }
    },
    onError: (res) => {
      res.json().then((data: AddPatientHookResponseError) => {
        data.errors.forEach((item) => {
          const { field } = item;
          setError(field, {
            type: 'custom',
            message: item.message,
          });
          if (item.message === 'Диалог: Пациент прикреплен в другой компании в этот же период. Прикрепить?') {
            setCrossingByCompanyPeriodModalOpen(true);
          } else if (
            item.message === 'Диалог: Пациент пересекается в рамках компании-срока обслуживания. Прикрепить?'
          ) {
            setCrossingByCompanyModalOpen(true);
          } else {
            enqueueSnackbar(item.message, { variant: 'error' });
          }
        });
      });
    },
  });
  const { handleAddFromFile } = useAddPatientFromFile({
    companyId: selectedCompanies?.[0]?.uuid || '',
    onSuccess: (data: TaskId) => {
      setCreateSlotTaskId(data.data.task_id);
      setIsModalOpen(true);
    },
    onError: () => enqueueSnackbar('Ошибка при отправке заявки на прикрепление', { variant: 'error' }),
  });
  const { dataTaskActionStaff, refetchTaskActionStaff } = useGetTaskActionStaff({
    taskId: createSlotTaskId,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const isTariffError =
    periodInMainFilter?.tariff?.map((item) => item.value).indexOf('questioning') !== -1 &&
    periodInMainFilter?.relativesInsuranceType === 'corporate_agreement';

  const handleChangeAddFromFile = async (evt: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    evt.preventDefault();
    const fileList: FileList | null = evt.target.files ? evt.target.files : null;
    const file: File | null = fileList !== null ? fileList[0] : null;
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file);

      analyticsTracker.trackAttachmentExcelAttempt();

      await handleAddFromFile(formData);

      analyticsTracker.trackAttachmentExcelSuccess();
    } else {
      enqueueSnackbar('Ошибка загрузки файла', { variant: 'error' });
      handleTaskIdAttach('');
    }
  };
  const refetch = (): void => {
    refetchTaskActionStaff();
  };

  const handleAttachClick = (): void => {
    analyticsTracker.trackAttachmentFormAttempt();
  };

  useEffect((): any => {
    if (isModalOpen && dataTaskActionStaff?.status !== 'success') {
      const interval = setInterval(() => {
        refetch();
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, dataTaskActionStaff]);

  const handleCloseConfirmation = (): void => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmClose = (): void => {
    handleCloseConfirmation();
    modalClose();
  };

  const handleModalClose = (): void => {
    if (isDirty) {
      setIsConfirmationOpen(true);
    } else {
      modalClose();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleModalClose} sx={dialogStyleAddModal}>
        <Box sx={modalHeaderStyle}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="h3" sx={modalTitleStyle}>
              Прикрепление нового пациента
            </Typography>
            <Typography variant="subtitle1" sx={modalSubtitleStyle}>
              Пользователь будет подключен к программе в самое ближайшее время!
              <br />
              Заполните анкету, чтобы прикрепить нового пациента, либо воспользуйтесь инструментом загрузки из файла
              (возможна загрузка сразу нескольких пациентов)
            </Typography>
          </Box>
          <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleModalClose}>
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>

        <Box sx={() => modalFormScrollStyle(600)}>
          <Box component="form" id="addingForm" sx={modalFormStyle} onSubmit={handleSubmit(handleAddPatient)}>
            <FormControl>
              <Typography variant="h4">Выберите категорию прикрепляемого пациента</Typography>
              <RadioGroup
                sx={{
                  padding: '19px 0 19px',
                }}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectedPatientType}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('patient_type', (event.target as HTMLInputElement).value, { shouldDirty: true });
                  register('patient_type', { value: (event.target as HTMLInputElement).value });

                  setselectedPatientType((event.target as HTMLInputElement).value);
                }}
              >
                <FormControlLabel
                  value="employee"
                  control={
                    <Radio
                      onChange={(item) => {
                        setselectedPatientType(item.target.value);
                      }}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '18px',
                      }}
                    >
                      Сотрудник
                    </Typography>
                  }
                />
                <TooltipActionsInfo
                  placement="right"
                  title={
                    periodInMainFilter?.relativesInsuranceType === 'individual_agreement' ||
                    periodInMainFilter?.relativesInsuranceType === 'disabled' ||
                    (periodInMainFilter?.relativesInsuranceType === 'corporate_agreement' && isTariffError) ? (
                      <GetTooltipTitleRelativeType
                        relativeType={periodInMainFilter?.relativesInsuranceType}
                        isTariffError={isTariffError}
                      />
                    ) : (
                      ''
                    )
                  }
                >
                  <Box
                    component="span"
                    sx={{
                      width: 'max-content',
                    }}
                  >
                    <FormControlLabel
                      value="relative"
                      control={
                        <Radio
                          disabled={
                            periodInMainFilter?.relativesInsuranceType === 'disabled' ||
                            periodInMainFilter?.relativesInsuranceType === 'individual_agreement' ||
                            (periodInMainFilter?.relativesInsuranceType === 'corporate_agreement' && isTariffError)
                          }
                          onChange={(item) => {
                            setselectedPatientType(item.target.value);
                          }}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 20,
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '18px',
                          }}
                        >
                          Родственник
                        </Typography>
                      }
                    />
                  </Box>
                </TooltipActionsInfo>
              </RadioGroup>
            </FormControl>
            <Collapse in={selectedPatientType === 'employee' || selectedPatientType === 'relative'}>
              <Typography
                variant="h4"
                sx={{
                  marginBottom: '20px',
                }}
              >
                Укажите данные пациента
              </Typography>
              <Box>
                <Box sx={() => modalFormWrapStyle(false)}>
                  <Box sx={modalFormWrapInputsStyle}>
                    <Box sx={() => modalFormInputStyle(false)}>
                      <TextField
                        label="Фамилия"
                        variant="standard"
                        error={!!errors.last_name?.message}
                        helperText={errors.last_name?.message}
                        sx={() => inputFormStyle(false)}
                        disabled={isLoadingAddPatient}
                        required
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              sx={{ width: '28px', height: '28px' }}
                              disabled={isLoadingAddPatient}
                              onClick={() => {
                                setValue('last_name', '', { shouldDirty: true });
                              }}
                            >
                              <CloseOutlinedIcon fontSize="small" />
                            </IconButton>
                          ),
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register('last_name')}
                      />
                    </Box>
                    <Box sx={() => modalFormInputStyle(false)}>
                      <TextField
                        label="Имя"
                        variant="standard"
                        error={!!errors.first_name?.message}
                        helperText={errors.first_name?.message}
                        sx={() => inputFormStyle(false)}
                        disabled={isLoadingAddPatient}
                        required
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              sx={{ width: '28px', height: '28px' }}
                              disabled={isLoadingAddPatient}
                              onClick={() => {
                                setValue('first_name', '', { shouldDirty: true });
                              }}
                            >
                              <CloseOutlinedIcon fontSize="small" />
                            </IconButton>
                          ),
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register('first_name')}
                      />
                    </Box>
                  </Box>
                  <Box sx={modalFormWrapInputsStyle}>
                    <Box sx={() => modalFormInputStyle(false)}>
                      <TextField
                        label="Отчество"
                        variant="standard"
                        error={!!errors.middle_name?.message}
                        helperText={errors.middle_name?.message}
                        sx={() => inputFormStyle(false)}
                        disabled={isLoadingAddPatient}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              sx={{ width: '28px', height: '28px' }}
                              disabled={isLoadingAddPatient}
                              onClick={() => {
                                setValue('middle_name', '', { shouldDirty: true });
                              }}
                            >
                              <CloseOutlinedIcon fontSize="small" />
                            </IconButton>
                          ),
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register('middle_name')}
                      />
                    </Box>
                    <Box sx={() => modalFormInputStyle(false)}>
                      <AutocompleteInput
                        label="Пол"
                        data={optionsGender || []}
                        disabled={isLoadingAddPatient}
                        required
                        handleChangeObj={(_event: React.SyntheticEvent, newValue: { title: string; value: string }) => {
                          setValue('gender', newValue.value, { shouldDirty: true });
                          register('gender', { value: newValue.value });
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={modalFormWrapInputsStyle}>
                    <Box sx={() => modalFormInputStyle(false)}>
                      <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label="Дата рождения"
                          value={dateBirth}
                          renderDay={rendersPickerDateBirth}
                          PopperProps={{
                            sx: datePickerPopperStyled,
                          }}
                          disabled={isLoadingAddPatient}
                          onChange={(newValue: Date | null) => {
                            if (newValue && isValid(newValue)) {
                              register('birth_date', {
                                value: format(newValue, 'yyyy-MM-dd'),
                              });
                              setValue('birth_date', format(newValue, 'yyyy-MM-dd'), { shouldDirty: true });
                              setDateBirth(newValue);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              label="Дата рождения"
                              variant="standard"
                              error={!!errors.birth_date?.message}
                              helperText={errors.birth_date?.message}
                              sx={() => inputFormStyle(false)}
                              disabled={isLoadingAddPatient}
                              required
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...params}
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <IconButton
                                      sx={{ width: '28px', height: '28px' }}
                                      disabled={isLoadingAddPatient}
                                      onClick={() => {
                                        setDateBirth(null);
                                        setValue('birth_date', '', { shouldDirty: true });
                                      }}
                                    >
                                      <CloseOutlinedIcon fontSize="small" />
                                    </IconButton>
                                    {params.InputProps?.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box sx={() => modalFormInputStyle(false)}>
                      <TextField
                        label="Гражданство"
                        variant="standard"
                        error={!!errors.nationality?.message}
                        helperText={errors.nationality?.message}
                        sx={() => inputFormStyle(false)}
                        disabled={isLoadingAddPatient}
                        required
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              sx={{ width: '28px', height: '28px' }}
                              disabled={isLoadingAddPatient}
                              onClick={() => {
                                setValue('nationality', '', { shouldDirty: true });
                              }}
                            >
                              <CloseOutlinedIcon fontSize="small" />
                            </IconButton>
                          ),
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register('nationality')}
                      />
                    </Box>
                  </Box>
                  <Box sx={modalFormWrapInputsStyle}>
                    <Box sx={() => modalFormInputStyle(false)}>
                      <TextField
                        label="Адрес фактического проживания"
                        variant="standard"
                        error={!!errors.address?.message}
                        helperText={errors.address?.message}
                        sx={() => inputFormStyle(false)}
                        disabled={isLoadingAddPatient}
                        required
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              sx={{ width: '28px', height: '28px' }}
                              disabled={isLoadingAddPatient}
                              onClick={() => {
                                setValue('address', '', { shouldDirty: true });
                              }}
                            >
                              <CloseOutlinedIcon fontSize="small" />
                            </IconButton>
                          ),
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register('address')}
                      />
                    </Box>
                  </Box>

                  <Collapse in={selectedPatientType === 'relative'}>
                    <Box sx={modalFormWrapInputsStyle}>
                      <Box sx={() => modalFormInputStyle(false)}>
                        <AutocompleteInput
                          required={selectedPatientType === 'relative'}
                          label="Кем приходится"
                          placeholder="Выберите связь"
                          data={periodInMainFilter?.relativeCategories || []}
                          disabled={isLoadingSearchPrograms || isLoadingAddPatient}
                          handleChangeObj={(
                            _event: React.SyntheticEvent,
                            newValue: { title: string; value: string }
                          ) => {
                            if (newValue) {
                              setValue('relative_type', newValue.value, { shouldDirty: true });
                              register('relative_type', { value: newValue.value });
                            }
                          }}
                        />
                      </Box>
                      <Box sx={() => modalFormInputStyle(false)}>
                        <AutocompleteInput
                          label="Кому приходится"
                          placeholder="Кому приходится"
                          handleClear={() => {
                            setValue('patient_relative', '', { shouldDirty: true });
                          }}
                          data={
                            searchPatientsRelative?.patients
                              ?.map((item) => ({
                                uuid: item?.uuid,
                                title: `${item?.title} ${item?.date_birth}`,
                              }))
                              .filter((item) => item.uuid !== watch('patient_relative_additional')) ||
                            [] ||
                            []
                          }
                          disabled={isLoadingAddPatient}
                          required={selectedPatientType === 'relative'}
                          handleInputChange={(_event: React.SyntheticEvent, newInputValue: string) => {
                            handleSearchText('');
                            setSearchRelativeText('');
                            handleSearchText(newInputValue);
                          }}
                          handleChangeObj={(
                            _event: React.SyntheticEvent,
                            newValue: {
                              title: string;
                              uuid: string;
                            }
                          ) => {
                            handleSearchText('');
                            if (newValue) {
                              setValue('patient_relative', newValue.uuid, { shouldDirty: true });
                              register('patient_relative', { value: newValue.uuid });
                            }
                          }}
                        />
                      </Box>
                    </Box>
                    <Collapse in={watch('relative_type') === 'child'}>
                      <Box sx={modalFormWrapInputsStyle}>
                        <Box sx={() => modalFormInputStyle(false)}>
                          <AutocompleteInput
                            label="Кем приходится"
                            placeholder="Выберите связь"
                            data={periodInMainFilter?.relativeCategories || []}
                            disabled={isLoadingSearchPrograms || isLoadingAddPatient}
                            handleChangeObj={(
                              _event: React.SyntheticEvent,
                              newValue: { title: string; value: string }
                            ) => {
                              handleSearchText('');
                              if (newValue) {
                                setValue('relative_type_additional', newValue.value, { shouldDirty: true });
                                register('relative_type_additional', { value: newValue.value });
                              }
                            }}
                          />
                        </Box>
                        <Box sx={() => modalFormInputStyle(false)}>
                          <AutocompleteInput
                            label="Кому приходится"
                            placeholder="Кому приходится"
                            data={
                              searchPatientsRelative?.patients
                                ?.map((item) => ({
                                  uuid: item?.uuid,
                                  title: `${item?.title} ${item?.date_birth}`,
                                }))
                                .filter((item) => item.uuid !== watch('patient_relative')) || []
                            }
                            disabled={isLoadingAddPatient}
                            handleInputChange={(_event: React.SyntheticEvent, newInputValue: string) => {
                              handleSearchText('');
                              setSearchRelativeText('');
                              handleSearchText(newInputValue);
                            }}
                            handleClear={() => {
                              setValue('patient_relative_additional', '', { shouldDirty: true });
                            }}
                            handleChangeObj={(
                              _event: React.SyntheticEvent,
                              newValue: {
                                title: string;
                                uuid: string;
                              }
                            ) => {
                              handleSearchText('');
                              if (newValue) {
                                setValue('patient_relative_additional', newValue.uuid, { shouldDirty: true });
                                register('patient_relative_additional', { value: newValue.uuid });
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    </Collapse>
                  </Collapse>
                  <Box sx={() => modalFormWrapStyle(false)}>
                    <Box sx={modalFormWrapInputsStyle}>
                      <Box sx={() => modalFormInputStyle(false)}>
                        <TextField
                          label="Телефон"
                          variant="standard"
                          error={!!errors.phone?.message}
                          helperText={errors.phone?.message}
                          sx={() => inputFormStyle(false)}
                          disabled={isLoadingAddPatient}
                          required={reqPhone}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                sx={{ width: '28px', height: '28px' }}
                                disabled={isLoadingAddPatient}
                                onClick={() => {
                                  setValue('phone', '', { shouldDirty: true });
                                }}
                              >
                                <CloseOutlinedIcon fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...register('phone')}
                        />
                      </Box>
                      <Box sx={() => modalFormInputStyle(false)}>
                        <TextField
                          label="E-mail"
                          variant="standard"
                          error={!!errors.email?.message}
                          helperText={errors.email?.message}
                          sx={() => inputFormStyle(false)}
                          disabled={isLoadingAddPatient}
                          required
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                sx={{ width: '28px', height: '28px' }}
                                disabled={isLoadingAddPatient}
                                onClick={() => {
                                  setValue('email', '', { shouldDirty: true });
                                }}
                              >
                                <CloseOutlinedIcon fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...register('email')}
                        />
                      </Box>
                    </Box>
                    <Box sx={modalFormWrapInputsStyle}>
                      <Box sx={() => modalFormInputStyle(false)}>
                        <TextField
                          label="Должность"
                          variant="standard"
                          error={!!errors.position?.message}
                          helperText={errors.position?.message}
                          sx={() => inputFormStyle(false)}
                          disabled={isLoadingAddPatient}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                sx={{ width: '28px', height: '28px' }}
                                disabled={isLoadingAddPatient}
                                onClick={() => {
                                  setValue('position', '', { shouldDirty: true });
                                }}
                              >
                                <CloseOutlinedIcon fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...register('position')}
                        />
                      </Box>
                      <Box sx={() => modalFormInputStyle(false)}>
                        <TextField
                          label="Табельный номер"
                          variant="standard"
                          error={!!errors.number?.message}
                          helperText={errors.number?.message}
                          sx={() => inputFormStyle(false)}
                          disabled={isLoadingAddPatient}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                sx={{ width: '28px', height: '28px' }}
                                disabled={isLoadingAddPatient}
                                onClick={() => {
                                  setValue('number', '', { shouldDirty: true });
                                }}
                              >
                                <CloseOutlinedIcon fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...register('number')}
                        />
                      </Box>
                    </Box>
                    <Box sx={modalFormWrapInputsStyle}>
                      <Box sx={() => modalFormInputStyle(false)}>
                        <TextField
                          label="Номер паспорта"
                          variant="standard"
                          error={!!errors.passport_number?.message}
                          helperText={errors.passport_number?.message}
                          sx={() => inputFormStyle(false)}
                          disabled={isLoadingAddPatient}
                          required={reqPass}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                sx={{ width: '28px', height: '28px' }}
                                disabled={isLoadingAddPatient}
                                onClick={() => {
                                  setValue('passport_number', '', { shouldDirty: true });
                                }}
                              >
                                <CloseOutlinedIcon fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...register('passport_number')}
                        />
                      </Box>
                      <Box sx={() => modalFormInputStyle(false)}>
                        <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            label="Дата выдачи"
                            value={datePassport}
                            renderDay={rendersPickerDatePassport}
                            PopperProps={{
                              sx: datePickerPopperStyled,
                            }}
                            disabled={isLoadingAddPatient}
                            onChange={(newValue: Date | null) => {
                              if (newValue && isValid(newValue)) {
                                register('passport_date', {
                                  value: format(newValue, 'yyyy-MM-dd'),
                                });
                                setValue('passport_date', format(newValue, 'yyyy-MM-dd'), { shouldDirty: true });
                                setDatePassport(newValue);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                label="Дата выдачи"
                                variant="standard"
                                error={!!errors.passport_date?.message}
                                helperText={errors.passport_date?.message}
                                sx={() => inputFormStyle(false)}
                                disabled={isLoadingAddPatient}
                                required={reqPass}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                InputProps={{
                                  endAdornment: (
                                    <>
                                      <IconButton
                                        sx={{ width: '28px', height: '28px' }}
                                        disabled={isLoadingAddPatient}
                                        onClick={() => {
                                          setDatePassport(null);
                                          setValue('passport_date', '', { shouldDirty: true });
                                        }}
                                      >
                                        <CloseOutlinedIcon fontSize="small" />
                                      </IconButton>
                                      {params.InputProps?.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                    <Box sx={modalFormWrapInputsStyle}>
                      <Box sx={() => modalFormInputStyle(false)}>
                        <TextField
                          label="Кем выдан"
                          variant="standard"
                          error={!!errors.passport_given?.message}
                          helperText={errors.passport_given?.message}
                          sx={() => inputFormStyle(false)}
                          disabled={isLoadingAddPatient}
                          required={reqPass}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                sx={{ width: '28px', height: '28px' }}
                                disabled={isLoadingAddPatient}
                                onClick={() => {
                                  setValue('passport_given', '', { shouldDirty: true });
                                }}
                              >
                                <CloseOutlinedIcon fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...register('passport_given')}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box sx={() => modalFormWrapStyle(false)}>
                  <Box sx={modalFormWrapInputsStyle}>
                    <Box sx={() => modalFormInputStyle(false)}>
                      <AutocompleteInput
                        label="Выберите программу"
                        placeholder="Выберите программу"
                        data={
                          programs && selectedPatientType
                            ? programs.filter(
                                (type) =>
                                  (type.allowedSlotPatientTypes &&
                                    type.allowedSlotPatientTypes?.includes('undefined')) ||
                                  type.allowedSlotPatientTypes?.includes(selectedPatientType)
                              )
                            : []
                        }
                        required
                        disabled={isLoadingSearchPrograms || isLoadingAddPatient}
                        handleChangeObj={(_event: React.SyntheticEvent, newValue: { title: string; uuid: string }) => {
                          setValue('program', newValue.uuid, { shouldDirty: true });
                          register('program', { value: newValue.uuid });
                        }}
                      />
                    </Box>
                    <Box sx={() => modalFormInputStyle(false)}>
                      <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label="Дата начала обслуживания"
                          value={dateStartProgram}
                          shouldDisableDate={(date) => disabledDays(date)}
                          renderDay={rendersPickerDateStartProgram}
                          PopperProps={{
                            sx: datePickerPopperStyled,
                          }}
                          disabled={isLoadingSearchPrograms || isLoadingAddPatient}
                          onChange={(newValue: Date | null) => {
                            if (newValue && isValid(newValue)) {
                              setDateStartProgram(newValue);
                              register('start_date', {
                                value: format(newValue, 'yyyy-MM-dd'),
                              });
                              setValue('start_date', format(newValue, 'yyyy-MM-dd'), { shouldDirty: true });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              label="Дата начала обслуживания"
                              variant="standard"
                              error={!!errors.start_date?.message}
                              helperText={errors.start_date?.message}
                              sx={() => inputFormStyle(false)}
                              required
                              disabled={isLoadingSearchPrograms || isLoadingAddPatient}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...params}
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <IconButton
                                      sx={{ width: '28px', height: '28px' }}
                                      disabled={isLoadingSearchPrograms || isLoadingAddPatient}
                                      onClick={() => {
                                        setDateStartProgram(null);
                                        setValue('start_date', '', { shouldDirty: true });
                                      }}
                                    >
                                      <CloseOutlinedIcon fontSize="small" />
                                    </IconButton>
                                    {params.InputProps?.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                  <Box sx={modalFormWrapInputsStyle}>
                    <Box sx={() => modalFormInputStyle(false)}>
                      <TextField
                        label="Доп. информация"
                        variant="standard"
                        multiline
                        error={!!errors.additional_info?.message}
                        helperText={errors.additional_info?.message}
                        sx={() => inputFormStyle(false)}
                        disabled={isLoadingAddPatient}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              sx={{ width: '28px', height: '28px' }}
                              disabled={isLoadingAddPatient}
                              onClick={() => {
                                setValue('additional_info', '', { shouldDirty: true });
                              }}
                            >
                              <CloseOutlinedIcon fontSize="small" />
                            </IconButton>
                          ),
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register('additional_info')}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Collapse>
          </Box>
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: '100%',
            padding: '8px 16px 16px',
          }}
        >
          <Typography variant="caption" sx={{ color: grayLightDarkOpacity }}>
            <Typography variant="caption" sx={{ color: themeColor }}>
              *{' '}
            </Typography>
            - обязательные поля.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '32px',
            }}
          >
            <Box>
              <MainButton handleClick={() => setModalAttachFromFileOpen(open)} textButton="Прикрепить из файла" />
            </Box>
            <TooltipActionsInfo
              placement="left"
              title={isAddingForbiddenByDates ? 'Нет доступных дат для прикрепления пациента' : ''}
            >
              <Box component="span">
                <MainButton
                  form="addingForm"
                  textButton="Прикрепить"
                  typeBtn="submit"
                  mainBtn
                  handleClick={handleAttachClick}
                  disabled={isLoadingAddPatient || !isDirty || isAddingForbiddenByDates}
                />
              </Box>
            </TooltipActionsInfo>
          </Box>
        </Stack>
      </Dialog>

      <Dialog
        open={isConfirmationOpen}
        onClose={handleCloseConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 2, minWidth: '300px' }}>
          <Stack spacing={2}>
            <Typography variant="h3" sx={modalTitleStyle}>
              Предупреждение
            </Typography>
            <Typography variant="body1" id="alert-dialog-title" sx={modalSubtitleStyle}>
              Если вы закроете форму, введенные вами данные пациента будут очищены!
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button onClick={handleCloseConfirmation} variant="outlined">
                Отмена
              </Button>
              <MainButton textButton="Закрыть" handleClick={handleConfirmClose} mainBtn />
            </Stack>
          </Stack>
        </Box>
      </Dialog>

      <ActionStaffModal
        celeryType={celeryType}
        textContent={modalAddFromFileContent}
        open={isModalOpen}
        displayErrors
        dataTaskActionStaff={dataTaskActionStaff?.data}
        handleClose={() => {
          setIsModalOpen(false);
          setModalAttachFromFileOpen(false);
          handleTaskIdAttach('');
          setCreateSlotTaskId(null);
          modalClose();
        }}
      />
      <ActionWithPatientFromFileModal
        status={dataTaskActionStaff?.data?.status.toLowerCase()}
        isActionMuted={isAddingForbiddenByDates}
        type="attach"
        companies={companiesHR}
        open={isModalAttachFromFileOpen}
        handleClose={() => setModalAttachFromFileOpen(false)}
        periodInMainFilter={periodInMainFilter}
        handleChangeAttachFromFile={handleChangeAddFromFile}
      />
      <CrossingByCompany
        open={isCrossingByCompanyModalOpen}
        handleClose={() => setCrossingByCompanyModalOpen(false)}
        handleCancel={() => setCrossingByCompanyModalOpen(false)}
        handleSubmit={() => handleAddPatient({ ...watch(), is_forced_attachment: true })}
      />
      <CrossingByCompanyPeriod
        open={isCrossingByCompanyPeriodModalOpen}
        handleClose={() => setCrossingByCompanyPeriodModalOpen(false)}
        handleCancel={() => setCrossingByCompanyPeriodModalOpen(false)}
        handleSubmit={() => handleAddPatient({ ...watch(), is_forced_attachment: true })}
      />
    </>
  );
};
